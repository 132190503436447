
.user-sponsor-slider
{
  height: 150px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}
.user-conversations
{
  margin-top: 35px;
}
.user-conversations-list
{
  margin-top: 15px;
  background-color: rgb(241, 241, 241);
  height: 500px;
  overflow: auto !important;
  scroll-behavior: auto;
}
.conversation-modal-popup
{
  width: 50% !important;
}
.conversations-popup
{
    height: 535px;
    overflow: auto;

}
.conversations-main-div
{
  padding: 10px; 
  margin-bottom: -15px;
  border-radius: 10px;
}
.conversations-header
{
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 15px !important;
  background-color: lightgray;
  padding-bottom: 15px;
  margin-bottom: -15px;
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
}

.conversations-popup::-webkit-scrollbar {
  width: 0;
}
.conversation-input {
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  flex-shrink: 0;
  box-shadow: 1px 2px 5px 1px lightgray;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  width: 95.5%;
  margin-left: 20px;
}

.attendee-conversation-text-area {
  width: 80%;
  height: 46px;
  border-radius: 20px;
  color: black;
  padding: 10px;
  margin-left: -20px;
}

.attendee-conversation-text-area:hover {
  border: none;
}

.attendee-conversation-emoji-picker {
  float: right;
  margin: 10px;
  padding: 4px;
  font-size: 25px !important;
}
.invited-btn
{
  margin-left: 15px !important;
  padding: 3px 10px 3px 10px !important;
}
.declined-button
{
  padding: 3px 10px 3px 10px !important;
}
.accept-button
{
  padding: 3px 10px 3px 10px !important;
  background-color: green !important;
}
.decline-button
{
  padding: 3px 10px 3px 10px !important;
  margin-left: -25px;
  background-color: peru !important;
}
.emoji
{
  bottom: 100px !important;
}
.last-message
{
  margin-left: 5px;
  font-style: italic;
  color: gray;
}
.attendee-chat
{
  height: 450px !important;
}
.exhibitor-chat
{
  height: 330px !important;
  background-color: white;
}

.attendee-chat::-webkit-scrollbar {
  width: 0;
}
.exhibitor-chat::-webkit-scrollbar {
  width: 0;
}
.attendee-conversation-input-section
{
  display: flex;
}
.attendee-conversation-input-img
{
  margin-left: 10px;
  margin-right: -10px;
  margin-top: 0px;
}
.attendee-conversation-emoji-picker
{
  margin: 10px;
}
.attendee-conversation-text-area
{
  width: 80% !important;
}
.attendee-conversation-input-send
{
  height: 35px;
  margin-top: 5px;
  padding: 0px 10px 0px 10px !important;
}
.attendee-conversations-username
{
  font-weight: bold !important;
  margin-left: 5px;
}
.attendee-conversarion-new-msg-div
{
  display: flex;
}
.attendee-conversarion-new-msg
{
  margin-left: 5px;
  margin-top: 2px;
  font-size: 10px;
  background-color: green;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 2px;
  font-style: normal;
  height: 16px;
}
.conversations-list-img-div, .conversation-popup-img-div
{
  margin-right: 10px !important;
}
