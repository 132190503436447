
.timeline__title {
  text-transform: uppercase;
  font-weight: 700;
}

.login-page{
  /*background-image: url('../images/event360-home.png');
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-color: rgb(241, 238, 238);
}

.login-form{
  height: 500px;
}
.Loading-button
{
  margin-right: 5px;
}
.pwd-button
{
  margin-top: 10px;
  background: none !important;
  border: none !important;
  font-size: 14px !important;
}

#otpbutton, #newpwdbutton, #cancelbutton
{
  display: none;
  margin-top: 29px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.form-control-sm
{
  border-radius: 6px !important;
}
#newpwd, #newpwdlabel, #otp, #otplabel
{
  margin-left: -13px !important;
}
#newpwd, #otp
{
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 10px;
  padding-right: 20px;
  width: 100% !important;
  margin-left: 0px !important;

}
.btn-login
{
  margin-top: -10px;
  width: 80%;
}

#newpwdlabel, #otplabel, #cancelotpbutton, #cancelpwdbutton
{
  display: none;
}
.button-section
{
  text-align: center;
}
.login-link
{
  color: white !important;
}
.show-password
{
  border-radius: 6px !important;
  width: 100% !important;
  margin-left: 10px;
}
.email-icon
{
  margin-left: -8px !important;
}
#newpwdfirsttimelabel
{
  display: none;
}
#newpwdfirsttime
{
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  margin-left: 0px;
}
#newpwdfirsttimelabel
{
  margin-left: -10px;
}
.btns-div
{
  margin-top: -20px;
  text-align: center !important;
}
#cancelbutton, #otpbutton, #newpwdbutton
{
  margin-bottom: -10px;
  width: 80% !important;
}
.login-circle-icon
{
  color: lightgray;
  margin-top: 5px;
}
.login-user-icon-div
{
  margin-top: -40px;
  text-align: center;
  margin-bottom: 0px;
}
#changepassword-result
{
  margin-top: -45px !important;
}
.show-pwd
{
  margin-left: -17px;
}
#message_result
{
  width: 100% !important;
}
#newpwd, #otp
{
  width: 105%;
}
.login-icons
{
  margin: 5px;
}
.login-popup-video-div
{
}

#userpassword {
  width: 95% !important;
  border-radius: 5px 0px 0px 5px !important;
}
.password-wrapper {
  display: flex;
}
.login-eye {
  width: 45px !important;
  height:38px !important;
  background-color: rgb(241, 238, 238);
  padding: 4px !important;
  border-radius: 0px 5px 5px 0px !important;
  cursor: pointer;
}