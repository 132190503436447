

.rdw-editor-toolbar {
  margin-left: 20px;
}

.exhibitor-submit-form-row #country, #region {
  height: auto !important;
}

.alert-warning {
  width: 100%;
}
.submit-form {
  width: 100% !important;
}

.form-clear-button {
  background-color: lightgray;
}
.form-submit-button {
  background-color: lightseagreen;
  color: white;
}
.form-back-button {
  background-color: deepskyblue !important;
  border-radius: 6px;
  padding: 0.25rem 0.5rem !important;
  color: white;
  margin-left: 10px;
  height: 40px !important;
}
.form-back-button2 {
  height: 40px;
  line-height: 35px;
  width: 40px;
  font-size: 3em;
  font-weight: bold;
  border-radius: 50%;
  background-color: deepskyblue;
  color: white;
  text-align: center;
  cursor: pointer;
}
.form-back-button2:hover {
  box-shadow: 0px 0px 1px 1px #bbbbbb;
  color: white;
}
.change-password {
  background-color: lightseagreen !important;
  border-radius: 6px;
  padding: 0.25rem 0.5rem !important;
  color: white !important;
  margin-left: 10px;
  margin-top: 30px;
}
.photodivstyles {
  text-align: center;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: -10px -20.5px 0px -20px;
  /* padding: 10px; */
}





/* -------------------------- company info ---------------- */
.company_card_wrapper {
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.company_card_header {
  background-color: rgba(0, 0, 0, 0.678) !important;
  color: white;
}
.uploadlogo{
  border: 2px solid rgba(133, 133, 133, 0.425);
}

.preview_button {
  appearance: none;
  background-color: #FAFBFC ;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E ;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  float: right;
}

.preview_btn:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.conpany_profile_icon_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profilephoto {
  border: 2px solid rgba(133, 133, 133, 0.425);
  width: 130px;
  border-radius: 5px;
  margin-top: -40px;
}

.select_c_dropdown {
  margin: 0px !important;
  border: 1px solid rgba(128, 128, 128, 0.402) !important;
}
.content_title_text {
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.402) !important;
}
.video-upload-input-wrapper {
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -5px !important;
}
.video_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.video_upload_button {
  appearance: none;
  background-color: #0145b3;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.video_upload_button:hover {
  background-color: #2c974b;
}

.upload_image_inputs {
  width: 100% !important;
  border: 1px solid rgba(128, 128, 128, 0.402) !important;
}
.uploaded_image_img {
  width: 200px;
}
.file_upload_inputs {
  margin-left: -8px !important;
}

.speaker_profile_icon_wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: -50px !important;
}
.qrcode-wrapper {
  margin-left: 20px !important;
  text-align: center !important;
}
.qrcode-d-btn {
  cursor: pointer;
  font-size: 12px !important;
}


@media screen and (max-width: 769px) {
  .login-credentials-form{
    display: flex;
    flex-direction: column;
  }
  .video_upload_button {
    margin-left: -16px !important;
  }
  .speaker_profile_icon_wrapper {
    margin-left: 0px !important;
  }
  
}