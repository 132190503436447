.sponsors-layout-maindiv
{
    text-align: center !important;
}
.sponsor-layout-div
{
    text-align: center;
    background-color: lightgray;
    width: 200px !important;
    margin-left: 10px;
    padding: 15px;
    height: 250px;
    margin-top: 10px;
    border: 1px solid lightblue;
}
.sponsor-layout-title, .sponsor-layout-company
{
    font-size: 13px !important;
}
.sponsor-layout-company
{
    margin-top: 10px;
}
.sponsor-layout-title
{
    margin-top: 15px;
    margin-bottom: -10px;
}
.sponsor-layout-name
{
    margin-top: 0px;
    margin-bottom: -10px;
}
.sponsor-layout-img-div
{
    text-align: center !important;
}
.sponsor-layout-img
{
    height: auto !important;
    width: 160px !important;
    max-width: 100% !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    max-width: 100% !important;
    max-height: 100% !important;
}
.sponsor-popup-div
{
    height: 500px !important;
    overflow-y: auto !important;
    color: black;
}
.sponsor-popup-div2
{
    margin-left: 50px;
    padding-left: 50px;
    vertical-align: middle;
    display: table-cell;
    height: 230px;
    max-width: max-content;
}
.sponsor-learnmore-button
{
    height: auto;
    width: 180px;
    max-width: 100%;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 100%;
    text-align: center;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #2e0654 !important;
    color: white;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    /*visibility: hidden;*/
}
.sponsor-contact-icon
{
    border-radius: 50px;
    padding: 10px;
    background-color: lightgray;
    margin-left: 10px;
}
.sponsor-contact-icon:hover
{
    box-shadow: 0px 0px 1px 3px gray;
}
.sponsor-contact-icons
{
    margin-top: 20px;
    margin-left: -10px;

}