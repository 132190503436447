.appointment-div
{
    height: 500px;
}
#appointment_schedule_button
{
    background-color: rgb(35, 116, 15);
    margin-bottom: 20px;
}
#appointment_reserved_button
{
    background-color: rgb(231, 41, 41);
    margin-bottom: 20px;
}
.appointments-header
{
    display: none;
    font-weight: bold;
    margin-bottom: 30px;
}
.appointment-time
{
    margin-top: 5px;
    color: black;
}
.appointment-hr
{
    margin-top: 10px;
}
.appointments-no-data
{
    text-align: center !important;
    color: gray;
}
.data-table
{
    overflow: auto;
}