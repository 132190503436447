
.auctions-search-div
{
  width: 30%;
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}
.auctions-search
{
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px 1px lightgray;
}
.progress-dot
{
    display: inline-flex !important;
}
.fa-circle
{
    margin-top: 7px;
}
.bids-section
{
    display: inline-flex;
}
.bidders-list
{
    padding-left: 10px;
}
.bidamount-form-control
{
    border-radius: 6px !important;
    width: 30% !important;
}
.bidamount-div
{
    display: inline-flex !important;
    margin-bottom: 20px;
}
.bidamount-icon
{
    margin: 5px;
}

.bids-div-1, .bids-div-2
{
    text-align: center;
}
.bids-div-3
{
    margin-left: 60px;
    margin-right: 0px;
    margin-top: 20px !important;
}
.bid-hr
{
    width: 80% !important;
}
.auctionitemms-before-login
{
    display: inline-flex !important;
    margin: 20px;
}
.private-img
{
    border-radius: 50%;
    object-fit: cover !important;
}
.bid-now-button
{
    text-align: center;
    width: auto !important;
    max-width: 100% !important;
}
.auction-item
{
    width: 415px !important;
}
.acution-item-desc
{
    float: left !important;
}
.bid-img
{
    object-fit: cover !important;
}

.bid-open
{
    background-color: rgb(34, 139, 34);
    float: left;
    padding: 5px;
    color: white;
    border-radius: 4px;
}

.bid-close
{
    background-color: rgb(179, 0, 0);
    float: left;
    padding: 5px;
    color: white;
    border-radius: 4px;
    margin-bottom: 10px;
}