/*      Sponsors Vertical       */
.sponsors-vertical-div
{
    text-align: center !important;
}
.sponsors-home
{
    margin-top: 40px;
}

.sponsors-vertical-title
{
    margin-top: 20px !important;
    margin-left: 30px;
    font-size: 18px !important;
}

/*      Sponsors Home 2     */     
.sponsors-category
{
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    text-align: center !important;
}
.sponsors-home-2
{
    display: inline-block;
    margin: 10px;
}
.sponsor-logo
{
    padding: 10px;
    height: auto;
    width: 300px !important;
}
.sponsors-home2
{
    text-align: center;
}
.private-img
{   
    border-radius: 50px;
    margin-left: 20PX !important;
    
}
.posts-avatar
{
    height: 45px !important;
    width: 45px !important;
    border-radius: 50px;
    margin-left: -8px;
}
.attendee-conversations-avatar
{
    height: 45px !important;
    width: 45px !important;
    border-radius: 50px;
    margin-left: -9px;
    margin-right: -10px;
}
.chat-avatar
{
    height: 40px !important;
    width: 40px !important;
    border-radius: 50px !important;
}
.sponsorsbycategory-img
{
    width: 250px;
    height: auto;
}
.sponsorsbycategory-maindiv
{
    text-align: center;
}
.sponsorsbycategory-categoryname
{
    margin: 0 0 25px 0;
    font-size: 20px;
    font-weight: bold;
}
.sponsorsbycategory-imgdiv
{
    display: inline-block;
    padding: 20px;
}
.meetup-avatar
{
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    margin-left: -7px;
}