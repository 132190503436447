.speakers-layout-slider-div
{
    margin-bottom: -40px !important;
}
.speakers-layout-maindiv
{
    text-align: center !important;
}
.speaker-layout-div
{
    text-align: center;
    background-color: lightgray;
    width: 100%s;
    margin-left: 10px;
    padding: 15px;
    height: 370px;
    margin-top: 10px;
}
.speaker-layout-title, .speaker-layout-company
{
    font-size: 13px !important;
}
.speaker-layout-company
{
    margin-top: 10px;
}
.speaker-layout-title
{
    margin-top: 15px;
    margin-bottom: -10px;
}
.speaker-layout-name
{
    margin-top: 0px;
    margin-bottom: -10px;
}
.speaker-layout-img-div
{
    text-align: center !important;
}
.speaker-layout-img
{
    width: 198px;
    height: 200px;
    margin-top: -7px;
    margin-left: -7px;
}
.speaker-layout-avatar
{
    width: 198px !important;
    height: 200px !important;
}
.speaker-popup-div
{
    height: 500px !important;
    overflow-y: auto;
    color: black;
}
.speaker-popup-div2
{
    margin-left: 50px;
    padding-left: 50px;
    vertical-align: middle;
    display: table-cell;
    height: 230px;
    max-width: max-content;
}
.speaker-learnmore-button
{
    height: auto;
    width: 180px;
    max-width: 100%;
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    text-align: center;
}