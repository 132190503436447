.session-info
{
  margin-top: -20px !important;
}
summary
{
  font-size: 20px;
  border: none;
  margin-bottom: 7px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
}

.tab-item
{
  margin-top: 20px;
}
.react-player {
  height: 590px !important;
  width: 1050px !important;
  max-width: 100%;
  border: none !important;
  margin-top: 0px;
}
.video-without-tabs
{
  width: 73%;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 1000px) {
  .video-without-tabs
  {
    width: 100%;
  }
  .react-player
  {
    width: 100% !important;
    height: 100% !important;
    margin-top: 10px;
  }
}

.logmein-logo
{
  text-align: center;
}
.zoom-logo
{
  text-align: center;
  width: auto;
  max-width: 100%;
}
.speaker-img
{
  border-radius: 50px;
}
.schedule-search-div
{
  width: 30%;
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}
.schedule-search
{
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px 1px lightgray;
}
.handouts
{
  display: flex !important;
  flex-wrap: wrap !important;
}

.timer-main-div
{
  box-shadow: 1px 1px 3px 1px lightgray;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  background-color: lightgray;
}
.speaker-name
{
  margin-left: 30px;
  margin-top: 15px;
}
.time-div
{
  margin-left: -15px;
}
.session-track-item
{
  box-shadow: 0.5px 0px 3px 2px lightgray;
  padding: 10px;
  border-radius: 6px;
}
.video-with-tabs
{
  margin-top: 10px;
}
.feedback-message
{
  background-color: rgb(223, 219, 219);
  padding: 15px;
  color: gray;
  font-size: 20px !important;
  box-shadow: 1px 1px 3px 1px lightgray;
  font-weight: bold;
}
.virtualexpo-live-btn
{
  background-color: red;
  padding: 9px 5px 5px 5px;
  margin-left: 10px;
  height: 23px;
  font-size: 7px;
  color: white;
  border-radius: 6px;
}



.live-text-schedule {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  background: red;
  text-align: center;
  padding: 4px;
  border-radius: 10px;
  margin-left: 10px;
  box-shadow: rgba(36, 36, 36, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  
}

.live-text-session
{
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 7px 10px 15px 10px;
  background-color: red;
  margin-left: 10px;
  display: inline-flex;
  height: 25px;
  color: white;
  border-radius: 6px;
  margin-bottom: 0px !important;
}
.session-back-button
{
  margin-top: 10px !important;
}
.time-watched
{
  color: white;
}
.display-timer
{
  color: white;
}
.session-back-title
{
  display: inline-flex;
  margin-top: -20px;
}
.session-title
{
  margin-top: 5px;
}
.session-date
{
  margin: 0 0 -10px 0px;
  padding: 5px 5px;
  
}
.fa-arrow-alt-circle-left
{
  font-size: 20px;
  color: gray;
}
.session-timer-display
{
  /*background-color: #0f94ec;
  text-align: center;
  border-radius: 6px;
  color: white;
  margin-bottom: 10px;
  padding-top: 5px;
  margin-top: -15px;
  box-shadow: 1px 2px 5px 2px lightgray;*/
 }
.session-timer
{
  margin-top: 5px;
  /*display: inline-flex;
  margin-bottom: -10px;*/
}
.session-timer-title
{
  padding-bottom: 5px;
}
.session-timer-time
{
  /*
  margin-bottom: -3px;
  margin-left: 10px;
  */
}
#checkindiv
{ 
  margin-top: 0px;
  text-align: center;
}
.checkin-div
{
  /*border: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px 10px 0 0;*/
  text-align: center !important;
}
#checkin_button
{
  background-color: white;
  border: none;
  margin-bottom: 20px;
}
.checkin-time
{
  text-align: center;
}
.checkin-code
{
  margin: 5px 0 15px 0;
  /*background-color: lightgray;
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  width: 50%;
  font-weight: bold;*/
}
#checkincode
{
  height: 40px;
}
#checkin2div
{ 
  margin-top: 0px;
  text-align: center;
}
.checkin2-div
{
  /*border: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px 10px 0 0;*/
  text-align: center !important;
}
#checkin2_button
{
  background-color: white;
  border: none; 
  margin-bottom: 20px;
}
.checkin2-time
{
  text-align: center;
}

.checkin2-code
{
  margin: 5px 0 15px 0;
  text-align: center !important;
  /*background-color: lightgray;
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  width: 50%;
  font-weight: bold;*/
}
.checkin2code-input{
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.checkin2code-input input,.checkin2code-input div {
  margin: 10px auto !important;
  width: 30% !important;
}
#checkin2code
{
  /* height: 40px;
  width: 50%;
  text-align: center !important; */
}
.session-livestream
{
  height: 500px;
}
.nav-tabs .nav-link.active {
  background-color: rgb(112, 112, 112) !important;
  color: white !important;
}
.nav-tabs .nav-link {
  color: black !important;
}
.tab-item{
  color: white !important;
}
.tabs-list
{
  text-align: center;
  margin-bottom: 20px;
}

.rwt__tab[aria-selected="false"]:hover,

.rwt__tab:focus {
  outline: none !important;
  background-color: rgb(211, 211, 211) !important;
}

.rwt__tablist .rwt__tab
{
  color: black !important;
  font-weight: bold !important;
  font-size: 18px;
}

.rwt__tablist
{
  font-weight: bold !important;
  background-color: rgb(250,250,250) !important; 
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  background-color: gray !important;
  color: white !important;
}
.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
	left: 0;
	width: 100%;
	border-bottom: 3px solid #00d8ff;
}
.shedule-titles{
  font-size: 17px;
  display: flex !important;
  align-items: center;
}
.speaker-wrapper-item{
  width: 100%;
  display: flex;
  padding-top: 8px;
  flex-wrap: wrap;
  height: auto;
}
.speaker-details-wrapper{
  width: 180px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0px;
}
.schedule-speakers-heading
{
  float: left;
  font-size: 20px;
  font-weight: bold;
}
.schedule-speaker-name
{
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding-top: 8px;
  text-overflow: ellipsis;
}
.session-halls
{
  background-color: #077ac2;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 1px 1px 3px rgb(236, 235, 235);
  font-size: 25px;
}
.session-halls:hover
{
  color: white;
}
.halla, .hallb, .hallc, .halld
{
  margin: 20px;
  text-align: center;
}

 .btn-session-registered
{
  color: white !important;
  width: 100%;
  text-align: center;
  padding: 7px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.btn-session-cancel
{
  color: white !important;
    width: 100%;
    text-align: center;
    padding: 10px 10px;
    width: 129px;
    border-radius: 17px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    margin-left: 0px !important;
}
.btn-session-register
{
  background-color: rgb(86, 160, 86) !important;
  color: white !important;
  width: 100%;
  text-align: center;
  padding: 8px 15px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  width: 129px;
}
.btn-session-group-register
{
  background-color: gray !important;
  color: white !important;
  width: 100%;
  text-align: center;
  padding: 7px ;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  width: 129px;
}
.btn-session-registered
{
  background-color: gray;
  width: 100% !important;
  cursor: default;
}
.btn-session-cancel
{
  background-color: peru;
  margin-left: 10px;
}

.schedule-time{
  font-size: 10px ;
}

.session-registration-message
{
  text-align: center !important;
  font-weight: bold;
  margin-top: 200px;
  margin-bottom: 200px;
}
.livesessions-message
{
  text-align: center !important;
  color: gray !important;
  margin: 100px !important;
  font-size: 20px !important;
}
.checkin-img
{
  width: auto !important;
  height: 100px;
}
.checkin-popup-btn-div
{
  float: right;
}
.popup-confirm-button
{
  margin: 5px;
}
.session-checkin-message
{
  text-align: center !important;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
.checkin-input-label
{
  font-weight: bold;
  font-size: 20px;
}
.checkin-success-msg
{
  font-weight: bold;
  margin: 10px;
  color: rgb(18, 95, 18);
}
.checkin-error-msg
{
  font-weight: bold;
  color: rgb(235, 57, 57);
  margin: -10px 0 10px 0;
}
.session-date-time
{
  display: block;
}
.speaker-avatar
{
  margin-left: 0px !important;
  text-align: center !important;
  font-size: 20px !important; 
}

.add-to-outlook
{
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: lightslategray;
  color: white;
  text-align: center;
}
.ical-calender
{
  margin-right: 5px;
}
.speaker-avatar-div
{
  text-align: center !important;
}
.schedule-speakers-div
{
  text-align: center !important;
  float: left;
}
.join-video-icon
{
  margin-right: 3px;
  margin-top: -2px
}
.schedule-speaker-popup
{
  cursor: pointer;
}
.game-btn{
  width: fit-content;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  font-weight: 200 !important;
  margin-left: -20px !important;
  font-size: 15px !important;
}

.download-presentation-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.download-presentation-wrapper a {
  box-shadow: rgba(2, 47, 174, 0.02) 0px 1px 3px 0px, rgba(55, 94, 247, 0.604) 0px 0px 0px 1px;
  padding: 2px 5px;
  font-size: 14px ;
  border-radius: 5px;
  margin: 5px;
}


@media(max-width:740px){
  .speaker-wrapper-item{
    justify-content: center;
    align-items: center;
  }

  .session-title {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    font-size: 16px !important;
   padding-bottom: 10px !important;
  }
  .checkin2code-input input,.checkin2code-input div {
    margin: 10px auto !important;
    width: 100% !important;
  }
  .download-presentation-wrapper {
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }
}






.session-tab {
  padding: 6px 14px;
  border-radius: 12px;
  margin: 5px;
  font-size: 18px !important;
  box-shadow: rgba(2, 47, 174, 0.02) 0px 1px 3px 0px, rgba(92, 92, 92, 0.604) 0px 0px 0px 1px;
}
.schedule-live-btn-div {
  border: 1px solid #ffbc00;
  padding: 5px 10px;
  border-radius: 17px;
  height: 34px;
  width: 130px;
  margin-bottom: 10px;
}
.schedule-live-btn-div a {
  font-size: 15px;
}
.shedule-action-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}



