.adss-layout-slider-div
{
    margin-bottom: -40px !important;
}
.adss-layout-maindiv
{
    text-align: center !important;
}
.ads-layout-div
{
    text-align: center;
    background-color: lightgray;
    width: 420px;
    margin-left: 10px;
    padding: 15px;
    height: 470px;
    margin-top: 10px;
}
.ads-layout-img-div
{
    text-align: center !important;
}
.ads-layout-img
{
    width: 420px;
    height: 470px;
    margin-top: -7px;
    margin-left: -7px !important;
}
.ads-layout-slider-div
{
    margin-bottom: -25px;
}