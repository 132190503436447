.speaker-actions-div {
    margin-left: -9px;
}

.action-dropdown {
    cursor: pointer;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0rem;
    padding-top: 0rem;
    border-radius: 100px;
    width: 15px !important;
    background: none !important;
    border: none !important;
}
  
.action-dropdown:hover {
    border-color: none;
}
  
.action-dropdown button {
    background-color: transparent !important;
}

.action-dropdown button:before {
    background-color: transparent !important;
}

.dropdown-content {
    display: flex;
    padding: 8px 10px;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    color: black;
    font-weight: 530;
    cursor: pointer;
}
  
.dropdown-content:hover {
    background-color: #f1f1f1;
    color: black !important;
    text-decoration: none;
}
  
.actions-verticaldots-icon:hover {
    font-weight: bold;
    background-color: none !important;
}

.new-button {
    height: 40px;
    line-height: 35px;
    width: 40px;
    font-size: 3em;
    font-weight: bold;
    border-radius: 50%;
    background-color: #ffdaa3;
    color: white;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
}
  
.new-button:hover {
    box-shadow: 0px 0px 1px 1px #bbbbbb;
}
.terms-group{
   margin-left: 18px;
}
#showdirectory,#termsstatus {
    width: 10px !important;
}

@media screen and (max-width: 769px) {
    .total-representative-count{
        font-size: 15px ;
        text-align: left;
        font-weight: 600;
    }
    .new-button {
        height: 30px;
        line-height: 25px;
        width: 30px;
        margin-right: 5px;
        margin-top: -5px;
    }
    .action_btns_wrapper {
        justify-content: left;
    }
    .action_btns_wrapper div:nth-child(2){
        margin-top: 10px;
    }
    .btn-group-password button{
        margin: 0 auto !important;
    }
    
}
.back_btn a {
    margin-left: -13px;
    border: 1px solid rgba(27, 31, 35, 0.15) !important;
    border-radius: 6px !important;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset !important;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px
}
.action_btns_wrapper div {
    display: flex;
    justify-content: center;
    align-items: center;
}