.virtual-info-div, .sponsor-schedule-div, .virtual-video-div, .virtual-videochat-div, .virtual-contact-div, .virtual-appointments-div, .videochat-login-message, .appointment-login-message
{
    height: 450px;
}
.virtual-exhibitor-logo
{
    height: auto;
    width: 150px;
}
.virtual-exhibitor-title
{
    font-weight: bold;
}
.virtual-exhibitor-info-div
{
    margin-bottom: -15px !important;
}
.virtual-exhibitor-info
{
    /* margin-top: -50px; */
    color: black;
}
.virtual-exhibitor-contact-header2, .virtual-exhibitor-contact-header1 
{
    margin-top: 20px !important;
    font-weight: bold;
    margin-bottom: -5px;
}
.video-chat-offline-message
{
    color: red;
}
.sponsor-schedule-header
{
    font-weight: bold;
    margin-bottom: -10px;
}
.videochat-login-message, .appointment-login-message
{
    text-align: center;
    color: gray;
    font-size: 18px;
}
.virtual-video-chat
{
    height: 450px;
    border: none;
}
.virtual-video-player
{
    margin-top: -10px;
    border-radius: 6px !important;
}
.virtual-info-div, .sponsor-schedule-div, .virtual-video-div, .virtual-appointments-div
{
    border-radius: 10px;
    padding: 10px;
}
.virtual-appointments-div
{
    overflow: auto;
    height: 450px;
}
.virtual-info-div, .sponsor-schedule-div
{
    overflow: auto;
    overflow-x: hidden;
}
.virtual-info-div
{
    color: black;
}
.virtual-chat-div
{
    height: 450px;
}
.virtual-exhibitor-img1
{
    /* height: 250px;
    width: auto;
    margin-top: 5px;
    margin-bottom: -30px;
    border-radius: 6px; */
    height: 250px;
    width: auto;
    margin-bottom: 20px;
    border-radius: 6px;
}
.virtual-exhibitor-banner
{
    text-align: center;
}
.exhibitor-contact-icon
{
    border-radius: 50px;
    padding: 10px;
    background-color: lightgray;
    margin-left: 10px;
}
.exhibitor-contact-icon:hover
{
    box-shadow: 0px 0px 1px 3px gray;
}
.exhibitor-contact-icons
{
    margin-top: 20px;
    margin-left: -10px;

}
.nav-link
{
    color: black;
}
.nav-link.active
{
    background-color: #707070!important;
    color: white!important;
}
hr{
    margin-top: 0rem;
}