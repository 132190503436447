
.card_wrapper {
   margin-top: 50px;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.custom_video_file {
    width: 95% !important;
}
.speaker_profile_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.rdw-editor-toolbar {
    margin-left: -0px !important;
}
.file_upload_input {
    margin-left: -10px !important;
}
.alert_message {
    margin-top: 30px;
}
.action_btns_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 30px;
}
.dec-error-message {
    color: rgb(208, 3, 3);
    font-size: 14px;
}
.text-max-count {
    font-size: 13px;
    position: absolute;
    right: 15px;
    top: 0px;
}

.action_btns_wrapper .form-clear-btn {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    margin-left: 30px;
}
.action_btns_wrapper .submit_btn {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.card_header {
    background-color: rgba(0, 0, 0, 0.678) !important;
    color: white;
}

.loading_section_wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-file-btn {
    float: right;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.delete-file-btn2 {
    float: right;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.select_items select {
    width: 95%;
}
#profilephoto {
    width: 150px;
    border-radius: 5px
}

.labels_column {
    padding: 3px !important;
}
.labels_column:nth-child(1) {
    margin-left: -3px !important;
}
.labels_column #Pronouns::placeholder,.form-labels input::placeholder {
    font-size: 12px !important;
    color: rgba(153, 151, 151, 0.704);
}

.form-labels input,.form-control-sm{
    width: 100% !important;
}
.custom_video_file {
    width: 100% !important;
}
.file_upload_input {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-top: 15px;
    margin-left: 0px !important;
    padding: 5px !important;
    border-radius: 5px !important;
}
.fileprogress_text {
    font-size: 13px;
    color: gray;
}
.file_downloaded {
    font-size: 13px !important;
}
#permission {
    width: 20% !important;
    border: 1px solid #80808052;
    margin-left: 10px;
    font-family: "Poppins";
}
.speaker-overview-ttile {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 600;
}
.speaker-overview-desc {
    font-family: "Poppins";
    font-size: 15px;
}
.permission-label-div{
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.permission-lable {
    font-weight: bold;
    margin-top: 8px;
    font-family: "Poppins";
    font-size: 16px;
}
.description-title span {
    font-size: 14px;
}

@media screen and (max-width: 760px) {
    .permission-lable {
        font-size: 14px;
    }
    .permission-label-div {
        text-align: center;
        padding: 10px !important;
    }
    #permission {
        width: 100% !important;
        margin-left: 0px;
        
    }
}