#schimg
{
  width: 100%;
  height: 500px;
}
.item-card
{
  margin-top: 40px;
}
.desc
{
  color: #7a7a7a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: left;
  line-height: 1.8em;
}
.username
{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
}
.title
{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  color:grey;
  font-size: 16px;
}
.speaker-image
{
  height: auto;
  width: 150px;
}
.speakers2-sponsors
{
  text-align: center !important;
}