.banner-div {
  height: 100px;
  text-align: center;
}

.presenting-logo-div {
  height: 100px;
  text-align: center;
}

.livestream-div {
  text-align: center !important;
  margin-top: 0px !important;
  height: 560px;
}

.livestream-iframe {
  width: 100%;
  height: 400px;
}

.rotating-banner-div {
  height: 150px;
  text-align: center;
}

.scrolling-sponsors-div {
  height: 100px;
  text-align: center;
}

.session-sponsor-ad1 {
  height: 100px;
  width: auto;
  max-width: 100%;
  margin-top: 10px;
}

.session-sponsor-ad2 {
  height: 150px;
  width: 150px;
  max-width: 100%;
}

.session-sponsor-logos {
  margin: 20px;
  height: 100px;
  width: auto;
  max-width: 100%;
}

.session-conference-banner {
  width: 1080px;
  height: 100px;
  max-width: 100%;
  margin-top: 10px;
  margin-left: -15px;
}

.schedule-agenda-tab {
  overflow: auto;
  height: 570px !important;
  /* background-color: rgba(226, 226, 226, 0.199); */
  border-radius: 6px;
  margin-top: 5px;
}

.sessioninfo2-react-player {
  width: auto !important;
  height: auto !important;
  margin-left: -10px !important;
  max-width: 100%;
  margin-top: 5px;
}

.sessioninfo2-video-div {
  text-align: center !important;
}

.zoom-embed-iframe {
  height: 550px;
  width: 100%;
  overflow: auto;
  margin-left: -30px;
  max-width: 100%;
}

.zoom-embed-iframe-layout1 {
  height: 570px;
  width: 100%;
  overflow: auto;
}

.embed-zoom-message-layout1 {
  text-align: center;
  padding-top: 10px !important;
  
}

.embed-zoom-message {
  padding-top: 10px !important;
}

.live-session-div {
  color: white !important;
}
.active-session-sessionpage {
  /* background-color: rgb(36, 90, 207, 1) !important; */
  /* color: white !important; */
  /* margin: -11px 0 0px 0;
  padding: 10px 0 10px 0; */
  margin: -5px 0px 0 0;
  padding-top: 10px;
}

.live-session-row {
  color: white !important;
}

.sponsor-scroll-div {
  margin-bottom: -24px !important;
}

.live-sessions-sessionpage-div {
  /* display: inline-flex; */
}

.sessioninfo2-live-session-sessionpage {
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 4px 10px 5px 10px;
  background-color: red;
  text-align: center;
  width: 60%;
  border-radius: 4px;
  align-self: center;
  margin-bottom: 0px;
  float: right;
  /* margin-left: 10px;
  height: 20px;
  color: white;
  border-radius: 6px;
  margin-bottom: 0px !important; */
}

.tabs-div {
  height: 450px !important;
  margin-top: 5px;
}

.chat-tab-sessioninfo2 {
  height: 500px;
  /* display: flex;
  flex-direction: column-reverse;  this is for scroll to bottom in a div*/
}

@media screen and (max-width: 960px) {
  .tabs-div {
    height: 600px !important;
    margin-top: 80px;
  }
  .iframe-embed-container
  {
    max-width: 100%;
  }
  .sessioninfo2-live-session-sessionpage {
    width: 20%;
    float: left;
  }  
  .session-time{
    width: 50%;
  }
  
}
#checkin2div
{ 
  margin-top: 0px;
  text-align: center;
}
.checkin2-div
{
  /*border: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px 10px 0 0;*/
  text-align: center !important;
}
#checkin2_button
{
  background-color: white;
  border: none;
  margin-bottom: 20px;
}
.checkin2-time
{
  text-align: center;
}
.checkin2-code
{
  margin: 5px 0 15px 0;
  /*background-color: lightgray;
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  width: 50%;
  font-weight: bold;*/
}
#checkin2code
{
  height: 40px;
}
.checkin2-img
{
  width: auto !important;
  height: 100px;
}
.checkin2-popup-btn-div
{
  float: right;
}
.popup-confirm-button
{
  margin: 5px;
}
.session-checkin2-message
{
  text-align: center !important;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
.checkin2-input-label
{
  font-weight: bold;
  font-size: 20px;
}
.checkin2-success-msg
{
  font-weight: bold;
  margin: 10px;
  color: rgb(18, 95, 18);
}
.checkin2-error-msg
{
  font-weight: bold;
  color: rgb(235, 57, 57);
  margin: -10px 0 10px 0;
}
.session-time
{
  font-size: 14px;
}
.sessioninfo2-sessiondiv-live
{

}
.session-info2-schedule-section {
  margin-left: -15px;
  margin-right: -5px;
  /* margin-bottom: -15px; */
  padding: 5px 0 5px 0;
  /* background-color: rgba(59,130,246,0.5) */
  background-color:rgba(188,193,205,1) !important;
  margin-bottom: 10px;
  border-radius: 6px;
  /* padding-bottom: 5px; */
}
.sessioninfo2-sepeakers-div
{
  height: 100px;
}
.sessioninfo2-session-title, .sessioninfo2-sessionheader-title, .live-session-title-sessionpage
{
  color: rgb(51, 47, 47) !important;
  font-size: 15px;
}
.sessioninfo2-sessionheader
{
  background-color: white !important;
  border: dashed !important;
  border-color: black !important;
  border-radius: 4px;
  padding: 5px;
  margin: -5px 0 -5px 0px;
}
.session-time
{
  background-color: rgba(64,71,86,1) !important;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  color: rgb(236, 233, 233) !important;
  width: 70px;
}
.sessioninfo2-speaker-img, .sessioninfo2-speaker-avatar
{
  height: 30px !important;
  border-radius: 50px;
}
.sessioninfo2-speakers-div
{
  display: block;
}
.iframe-embed-container
{
  width: 106%;
}
.wait-for-checkinmsg-data{
  /* background-color: rgba(64,71,86,1);
  color: white;
  padding: 5px;
  border-radius: 4px;
  margin-top: 5px; */
}