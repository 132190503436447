
.cardtitle {
  font-weight: bold;
}

.cardsubtitle {
  font-size: 20px;
}

#cardimg {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

#card:hover {
  background-color: lightskyblue;
}

.desc {
  color: #7a7a7a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: left;
  line-height: 1.8em;
}

.username {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
}

.title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  color: grey;
  font-size: 16px;
}



.p {
  font-family: "Roboto", sans-serif;
  color: black;
  text-align: left;
  font-size: 20px;
}

.vertical-line {
  border-left: 1px solid lightgray;
  height: 500;
}

.speakers-heading {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 20px;
  color: lightseagreen;
  font-weight: bold;
}

.speaker-title {
  padding: 10px;
  width: 348%;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  position: absolute;
  top: 94.5%;
  left: 26.5%;
  height: 50px;
  display: inline-block !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
}



.speaker-img {
  border-radius: 100px;
}

.info, .speakers {
  text-align: center !important;
}
.speakers img{
  margin-top: 30px !important;
}
.speaker-sponsor {
  text-align: center !important;
  width: 100% !important;
}

.speakers-home {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
  width: 100% !important;
}
.speaker-category-t{
  width: 1100px !important;
  margin-top: 0px;
  text-align: left;
  
}

/**************************Speraker Info *******************************/

.speaker-banner {
  background: linear-gradient(90deg, #00B4DB, #0083B0);
  width: 100%;
  top: 0px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.speakerInfo-name {
  color: white;
  font-size: 50px;
  font-weight: bold;
  margin-top: 90px;
}

.speakerInfo-title {
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.speakerInfo-title2 {
  color: black;
  font-size: 25px;
  font-weight: 600;
}

.speakerInfo-stitle {
  color: black;
  font-size: 25px;
}

.speakerInfo-stime {
  color: black;
  font-size: 25px;
}

.speakerInfo-desc {
  font-size: 16px;
}

.speaker-info {
  margin-top: 40px;
}

.speakerInfo-image {
  height: 300px;
}

.info-img {
  border-radius: 100px;
  border: 2px solid white;
  height: 200px;
  width: 200px;
  margin-left: 80px;
}

.common-profile {
  border-radius: 100px;
}

.speaker-img-popup-div {
  cursor: pointer;
}


.speakersCustomModal {
  margin-top: 100px;
}



/* ------------------------------------ list speaker ------------------------------------ */
.list-speaker-wrapper{
  width: 100% !important;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px;
}

.list-speaker-image{
  width: 250px !important;
  height: 250px !important;
}
.list-speaker-image img{
  width: 100% !important;
  height: 100% !important;
  border: none;
}
.list-speaker-details{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
}
.list-speakers-description .section p{
  text-align: left;
}
/* .section p:nth-child(2){
  display: none;
} */
.list-speaker-details button{
  width: 150px;
  margin-top: 15px;
  border: none;
}
.showSection{
  display: block !important;
}

.speakers-lists{
  display: flex;
  justify-content: center !important;
}

.speaker-item-card{
  display: flex;
  justify-content: center;
  align-items: center;
}
.speaker-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.speaker-name-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-top: 15px !important;
}
