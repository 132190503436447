.container {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  width: 100%;
}

.chat-messages {
  /*overflow-y: scroll;*/
  overflow-x: hidden;
  flex-grow: 1;
  padding: 20px;
  box-shadow: 1px 1px 3px 1px lightgray;
  overflow-y: auto;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.chat-messages::-webkit-scrollbar {
  width: 0;
}

.comments {
  /*overflow-y: scroll;*/
  overflow-x: hidden;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  /*height: 400px;*/
}

h3 {
  text-align: center;
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
}

.chat-input {
  position: relative;
  box-shadow: 1px 1px 3px 1px lightgray;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 10px;
  background-color: gray;
}

.chat-input-div {
  width: 100% !important;
}

.chat-input-textarea {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(216, 215, 215);
  color: black;
  padding-left: 20px;
  padding-top: 8px;
  margin-left: 10px;
}

.chat-input-textarea {
  border: none;
}

.emoji-picker {
  margin-top: 5px;
  z-index: 1;
  margin-left: 13px;
  font-size: 25px !important;
}

.emoji {
  color: gray !important;
}

.message-date {
  font-size: 12px;
  color: gray;
  margin-left: 5px;
}

.message.from-me .username {
  display: none;
}

.message-body-from-me {
  padding: 8px;
  border: 1px;
  border-radius: 5px 5px 0px 5px;
  font-size: 15px;
}

.sub-div-from-me {
  display: flex !important;
  justify-content: flex-end !important;
}

.message {
  margin-bottom: 20px;
}

.message-body {
  display: inline-block;
  padding: 8px;
  border: 1px;
  border-radius: 0px 5px 5px 5px;
  font-size: 15px;
}

.username {
  font-weight: bold;
  font-size: 0.7rem;
  color: #999;
  margin-bottom: 5px;
  display: flex;
  text-align: center;
}

.message-date-from-me {
  float: right;
  margin-bottom: 8px !important;
}

.sender-photo-from-me {
  float: right;
  margin-top: -50px;
}

.username.from-me {
  float: right !important;
}

.main-div {
  display: flex;
}

.sub-div1 {
  padding-right: 8px;
}

.session-chat-div1 {
  margin-right: 25px;
}

.chat-input-send {
  padding: 0px;
  margin-left: 13px;
}