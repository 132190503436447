.item-card {
  width: 100% !important;
  margin-left: 0px !important;
}

.user-list {
  display: block !important;
}

.attendee-img {
  margin-top: 10px !important;
  text-align: center;
}

.attendee-card-info {
  text-align: center;
  padding-top: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.attendee-badge {
  background-color: peru;
  color: white;
  padding: 5px;
  text-align: center !important;
  margin-bottom: 5px;
  border-radius: 4px;
  font-size: 14px;
  width: 90%;
}

.attendee-badge-div {
  text-align: center !important;
  margin-left: 25px;
}

.card {
  height: auto !important;
  /*
  background-color: #fafdff !important;*/
}

.card:hover {
  /*box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
  /*background-color: #fffbf5 !important;*/
}

.attendee-search-div {
  width: 30%;
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}

.attendee-search {
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px 1px lightgray;
}

.common-profile {
  border-radius: 100px;
}

.fab {
  font-size: 25px;
  margin: 0;
}

.address-city {
  margin-top: -14px;
}

.address-state {
  margin-top: -14px;
}

.address-street {
  margin-top: 5px;
}

.social-div {
  margin-top: -20px;
  text-align: center;
  margin-bottom: 0px;
}

.attendee-card-icon {
  text-align: center;
  margin: 5px;
}

.attendee-card-icon:hover {
  box-shadow: 0 8px 16px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: black;
}

.attendee2-card-icon {
  border-radius: 50px;
  padding: 5px;
  background-color: rgb(226, 225, 225);
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 0px;
  color: white !important;
}

.attendee2-card-icon:hover {
  box-shadow: 0px 0px 1px 3px gray;
  border-radius: 50px;
  color: white !important;
}

.phone-div {
  margin: -20px 0 5px 0 !important;
}

.email-div {
  margin-top: 0px;
}

.attendee-title {
  margin-top: -15px !important;
  margin-bottom: 5px;
}

.attendee-company {
  margin-top: -10px;
}

.card2 {
  background-color: #f3f3f3;
  box-shadow: 1px 2px 3px 1px lightgray;
  padding: 10px;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  height: 200px;
}

.attendee-title2 {
  margin-top: -17px;
}

.address-city2 {
  margin-top: -14px;
  margin-left: 15px !important;
}

.address-state2 {
  margin-top: -14px;
}

.address-street2 {
  margin-top: 5px;
}

.social-div2 {
  text-align: center !important;
  width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  margin-top: 25px ;
  margin-left: -10px;
}

.phone-div2 {
  margin-top: 5px !important;
  margin-top: -1px !important;
}

.email-div2 {
  margin-top: -16px;
}

.card-attendee{
  display: flex;
  align-items: center;
}

.attendee-info-wrapper{
  height: 200px;
  display: flex;
  align-items: center;
}

.attendee-info-div2 p{
  width: 100% !important;
  font-size: 14px !important;
  
}

.attendee-title2, .attendee-company2, .attendee-info-div2, .email-div2, .phone-div2, .address-street2, .address-street2, .attendee-name2 {
  text-align: left;
  margin-left: 15px !important;
}
.attendee-name2 b{
  font-size: 15px !important;
}
.attendee-company2 {
  margin-top: -8px;
}

.common-profile {
  max-width: 100%;
}

.private-img {
  margin-left: -15px;
}

.attendee-img2-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.attendee-avatar2 {
  border-radius: 50px;
  width: 100px;
  height: 100px;
}

.attendee-avatar2-div {
  float: left !important;
  margin: 5px -20px -20px -20px !important;
  margin-top: -30px !important;
}

@media only screen and (max-width: 800px) {
  .card2 {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px
  }
  .attendee-img2-div {
    margin-top: -70px;
  }
  .attendee-search-div
  {
    width: 90%;
  }
}

@media only screen and (max-width: 1300px) {
  .card2 {
    height: 250px;
  }
}

@media only screen and (max-width: 1000px) {
  .card2 {
    height: 350px;
  }
}

.attendee-avatar {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50px;
  margin: 0 10px 0 5px;
}

.delegate-chat {
  cursor: pointer;
  color: lightgray;
  font-size: 25px;
}

.delegate-chat:hover {
  color: gray;
}

.attendee-badge-level-div {
  float: right;
  margin-right: -25px;
  margin-top: 10px;
}

.attendee2-badge-level-div {
  width: 50%;
  text-align: center !important;
  margin-top: 15px;
  margin-left: 75px;
}

.attendee-badge-level-bronze, .attendee-badge-level-silver, .attendee-badge-level-gold {
  padding: 1px 5px 1px 5px;
  border-radius: 4px;
}

.attendee2-badge-level-bronze, .attendee2-badge-level-silver, .attendee2-badge-level-gold {
  padding: 1px 5px 1px 5px;
  border-radius: 4px;
}

.attendee-badge-level-icon {
  margin-top: -5px;
}

.attendee-q1 {
  margin-top: -5px;
  margin-left: 15px;
  font-weight: bold !important;
}

.attendee-q2 {
  margin-top: -15px;
  margin-left: 15px;
  font-weight: bold !important;
}