.registration-home {}

/* RegInfo */

.reg-info, .my-info-div, .reg-options-div, .attendee-info-div, .add-guest-div, .reg-questions-div, .reg-payment-div, .reg-sessions-div, .terms-div {
    border-radius: 6px;
    background-color: white;
    padding: 20px 40px 20px 40px;
    margin: 25px 0px 25px 0px;
}

/* My-Info */

.my-info-login {
    margin-top: 31px;
    background-color: peru !important;
}

/* Registration Options */

.reg-option-title {
    border-bottom: 1px solid rgb(235, 232, 232);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.reg-options-table-header {
    border-bottom: 2px solid gray;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.reg-radio-registered{
    text-align: center !important;
    margin-left: 40px;
}

@media screen and (max-width: 769px) {
    .reg-options-table-header {
        display: none !important;
    }
    
    .attendee-reg-item {
        width: 100%;
        font-size: 13px;
    }
    .reg-price div{
        font-weight: bold !important;
        padding-bottom: 5px;
    }
    .input-wrapper-section,.regitem-soldout{
        float: left;
        
    }
    
    .payment-total-wrapper{
        padding-bottom: 50px !important;
        position: relative;
    }
    
    .reg-payment-invoice{
        padding-left: 20px !important;
    }
    .attendee-input-wrapper{
        min-width: 300px;
    }
    .registration-banner {
        width: 100%;
    }
    .registration-banner img {
        width: 100%;
    }
}
.error-message-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.error-message-wrapper h6 {
    margin-top: 10px;
    margin-left: 6px;
    font-weight: 600;
}




.reg-checkbox, .reg-radio {
    margin-left: 120px;
    width: 20px;
    height: 20px;
}

.input-wrapper-section input[type="radio"],.input-wrapper-section input[type="checkbox"]{
    margin-left: 45% !important;
    text-align: center;
}

.input-wrapper-section div {
    font-size: 12px;
    color: gray;
}
.regitem-soldout {
    font-weight: bold;
    text-align: center;
    color: peru;
}
.attendee-input-wrapper input::-webkit-input-placeholder {
    color: rgb(220, 219, 219);
  }



/* Attendee Info */
.attendee-input-wrapper{
    margin-left: -20px !important;
}
.attendee-input-wrapper input{
    width: 100%;
}


.reg-option-section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}

.reg-option-section {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: transparent;
}
.reg-option-section p {
    font-weight: bold;
    font-size: 19px;
    font-family: Poppins, sans-serif;
    margin: 0px;
}
.attendee-reg-item-desc details {
    margin-top: 0px !important;
}

.regform-data-section {
    margin-top: 0px;
}

.attendee-regoption-price-header {
    float: right;
}

.add-attendee {
    background-color: #129CF3 !important;
    color: white;
}

.add-attendee-div {
    float: right;
    padding: 0 0 10px 10px;
}

.attendee-popup {
    height: 610px;
    margin-top: 80px;
}

.attendee-popup-close-icon {
    margin-top: 55px !important;
}

.reg-form-buttons-div {
    float: right;
}

.reg-form-save-btn {
    background-color: peru !important;
    margin: 0 10px 0 0px;
}

.reg-form-cancel-btn {
    background-color: gray !important;
    margin: 0 30px 0 10px;
}


.reg-questions-header2 {
    text-align: center;
}

.reg-question {
    font-weight: bold !important;
    font-size: 18px;
}

.reg_dropdown_item {
    font-weight: bold !important;
    font-size: 18px;
}

.form-control {
    border: 1px solid lightgray !important;
    border-radius: 4px !important;
    margin-left: 0px !important;
}

#region, #country {
    width: 95%;
    margin-left: 20px;
}

#reg-email {
    margin-left: 20px;
    width: 100% !important;
}
.reg-discounts-div-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-discount {
    font-size: 16px !important;
    padding: 7px 35px !important;
    margin-top: 15px;
}

#discount {
    margin-left: 0px;
}

.discount-message {
    margin-top: 10px;
}
.discount-message div,.payment-message {
    font-weight: bold;
    font-family: "Poppins";
    text-align: center;
    font-size: 15px;
}

.btn-register {
    background-color: rgb(54, 114, 54) !important;
    float: center;
    margin-left: 15px;
}

.login-message {
    text-align: center;
    margin-left: 15px;
    font-size: 18px;
    margin-top: 30px;
    font-weight: 600;
}
.payment-message {
    padding-top: 25px;
    padding-bottom: 10px;
}

.payment-div {
    text-align: center;
}

.payment-items, .payment-amount {
    font-size: 20px;
}

.payment-amount {
    margin-bottom: 5px;
}


.reg-continue-div {
    text-align: center;
}

.payment-success {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -20px;
}

.payment-login, .payment-login-check, .payment-login-payroll, .payment-login-free {
    text-align: center !important;
    font-weight: bold;
    /*background-color: rgba(226, 224, 224, 0.774);
    color: white;*/
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.payment-login-check, .payment-login-payroll, .payment-login-free {
    width: 30%;
}

.paymentmethod-desc {
    color: gray;
    font-size: 14px;
    margin: -15px 0 0 20px;
}

.reg-session-button {
    text-align: center;
}

.reg-session-title {}

.reg-session-div {
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    height: 550px;
    margin-right: 0px;
    scroll-padding: 0;
}

.term-question-div {
    display: flex;
    align-items: flex-start;
    margin-left: -100px;
}
.term-question-div input{
    width: 20px !important;
    height: 20px !important;
    margin-left: 110px !important;
}
.term-question {
    width: 100%;
    margin: -2px 0 20px 10px !important;
    font-weight: bold;
}

.reg-tabs {
    margin-top: 10px !important;
}

.reg-tabs-list {
    margin-bottom: 0px;
}

.reg-question-input {
    margin-left: 0px !important;
}

.reg-questions-table-header {
    margin: 0px;
    padding: 0px;
}

.refresh-payment-total {
    margin-left: 10px;
    cursor: pointer;
}

.reg-options-table-header3 {
    text-align: center;
}

.reg-question-input {
    width: 100% !important;
}

.reg-loading-text {
    vertical-align: middle;
    display: table-cell;
    font-size: 25px;
    font-family: futura;
    width: 100%;
    margin: 0 auto;
}
.loading-button-div
{
    text-align: center;
}
#country, #region {
    padding-top: 8px;
    height: 37px;
}

.guest-reg-option-after-login {
    text-align: center;
    margin-left: 105px !important;
    width: 20px;
    height: 20px;
}

.guest-reg-option-before-login {
    text-align: center;
    margin-left: 105px;
    width: 20px;
    height: 20px;
}

.attendee-summary {
    margin: 10px;
}

.attendee-summary-label {
    font-weight: bold;
}

.guest-summary-div {
    border-left: 1px solid lightgray;
}

.attendee-reg-item {
    display: flex;
    align-items: center;
}
.regitem-text{
    font-weight: bold;
    font-family: "Poppins";
    font-size: 16px;
    letter-spacing: 1px;
}
.more-info-wrapper-section {
    width: 650px;
    max-width: 650px;
    max-height: 550px;
    overflow-y: auto;
}
.reg-moreinfo-icon {
   margin-left: 10px;
   color: #0274be;
   cursor: pointer;
}
.newlable-div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.newlable-div p {
    position: relative;
    top: 7px;
}
.moreinfo-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-bottom: 5px;
    font-family: "Poppins";
}
.more-info-table {
    border-collapse: collapse;
    width: 100%;
}
.more-info-table th, td {
    border: 1px solid rgba(189, 189, 189, 0.684);
    padding: 8px;
    text-align: left;
}
.more-info-table th {
    background-color: #e8e8e8;
    font-weight: bold;
    font-family: "Poppins";
}
.more-info-table td {
    color: black;
    font-weight: 500;
    font-family: "Poppins";
}
.total-amount-item {
    color: black;
    font-weight: 500;
    font-family: "Poppins";
}
.more-info-title {
    font-weight: bold;
}
.more-info-reg-description details {
    padding-top: 15px;
}
.more-info-reg-description details ul {
    padding-left: 30px;
}

.more-info-description details ul {
    padding-left: 50px;
}
.attendee-reg-item-desc {
    font-size: 14px;
    font-style: italic;
    color: gray;
    margin-top: 0px;
}

.registrationform-dropdown {
    width: 100% !important;
}

.basic-multi-select-single {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    color: #1a1a1adb;
    box-shadow: rgb(8 8 8 / 18%) 0px 1px 4px;
    border: none;
    font-family: "Poppins";
}

.remaining-count {
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
    color: gray;
    font-family: "Poppins";
    margin-bottom: 5px;
}

.reg-price {
    float: left;
    font-size: 15px;
}

.reg-signups-remaining {
    text-align: center;
    font-size: 12px;
    color: gray;
    margin-left: 40px;
}


.ach-form-control {
    margin-left: 0px !important;
}

.ach-payment-failed {
    text-align: center;
    font-weight: bold;
    color: peru;
    margin: 10px;
}

.basic-multi-select {
    width: 100%;
}

.ach-copyright {
    text-align: center;
}

.strikeout-price {
    color: peru;
}

.attendeeinfo-form-label {
    display: flex !important;
    margin: -5px 0px 0px 0px;
}

.attendee-info-required-symbol {
    font-weight: bold;
    color: red;
    margin-left: 5px;
    font-size: 20px;
    margin-bottom: -10px;
}

.razorpay-pay-button {
    background-color: #016776;
    border: none;
    padding: 5px;
    width: 50%;
    color: white;
    font-weight: bold;
}

.razorpay-btn-div {
    text-align: center;
}

.attendee-summary-pending-amount-label, .attendee-summary-pending-amount {
    color: red;
    font-weight: bold;
}

.ach-payment-amount {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
}

.sponsor-popup {
    margin-top: 20px;
}

.achcustomModal, .paymentpopup {
    height: 720px;
    width: 1000px !important;
    margin-top: 100px;
}
.myinfo-section-header
{
    display: block;
}
.invoice-pdf-icon
{
    size: 10px;
    color: #129cf3;
}
.reg-payment-invoice
{
    float: right;
    cursor: pointer;
    margin-top: -60px;
}
.attendee-info-show-directory
{
    text-align: left;
    margin-left: 35px !important;
    margin-bottom: -10px !important;
}
#showdirectory
{
    min-width: 20px;
}

.more-info-popup {
    min-width: '550px';
    border-radius:'10px'
}

.reg-collapse-card {
    margin-bottom: 20px;
    border: none !important;
}
.reg-collapse-card .reg-collapse-card-body{
    border: none !important;
}
.reg-item-input-wrapper {
    padding-bottom: 10px;
}
.input-wrapper-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
}
.reg-text {
    padding: 3px;
    text-align: center;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
}
.available-count-text {
    text-align: center;
    padding-top: 5px;
}
.reg-discounts-div {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.reg-discounts-input {
    width: 100%;
}
.discount-apply-btn {
    margin-left: 15px;
    display: flex;
    align-items: end;
}
.reg-continue-div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.btn-reg-continue {
    width: 150px;
}

@media screen and (max-width: 769px) {
    .reg-options-table-header {
        display: none !important;
    }
    
    .attendee-reg-item .regitem-text{
        font-size: 13px;
    }
    .reg-price div{
        font-weight: bold !important;
        padding-bottom: 5px;
    }
    .input-wrapper-section,.regitem-soldout{
        float: left;
        
    }
    
    
    .reg-payment-invoice{
        padding-left: 20px !important;
    }
    

    .attendee-input-wrapper{
        min-width: 300px;
    }
    .registration-banner {
        width: 100%;
    }
    .registration-banner img {
        width: 100%;
    }
   
    .more-info-wrapper-section {
        width: auto;
    }

    .reg-collapse-card-body {
        margin: 0px !important;
        padding: 0px !important;
    }
    .reg-item-input-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .reg-price {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .reg-item-wrapper {
        border-bottom: 1px solid rgba(128, 128, 128, 0.442);
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .regitem-text{
        text-align: center;
    }
    .attendee-reg-item {
        justify-content: center;
    }
    .error-message-wrapper {
        flex-direction: column;
        text-align: center;
    }

    .reg-discounts-div {
        flex-direction: column;
    }
    .discount-apply-btn {
        margin-left: 0px;
        justify-content: center;
        align-items: center;
    }
    .btn-discount {
        width: 150px;
    }
    .reg-option-section {
        margin-bottom: 0px;
        padding-bottom: 20px;
    }
    .section-total {
        padding-top: 7px;
    }
    
}