
#hello
{
  text-align: center;
  color: gray;
}
#map
{
  margin-top: 30px;
  border: none;
  width: 100%;
  margin-bottom: 10px;
}

input[type=text] {
  
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;    
}

textarea {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;    
  width: 95% !important;
  margin-left: 20px !important;
  height: 200px;
}
.contact-textarea
{
  width: 100% !important;
}
.label
{
  margin-left: 20px;
  color: gray;
}
.contact-form
{
    height: 520px !important;
    margin-top: -20px;
}
.btn-submit-contact{
  background-color: dodgerblue;
  color: white;
  padding: 9px 30px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
}
.btn-submit-contact:hover{
background-color: rgb(250, 136, 7);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.reg-query
{
  margin-bottom: 30px;
}

.contact-info
{
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 1px 2px 5px 2px lightgray;
    margin-left: -13px;   
}
.contact-icons
{
  margin: 5px;
}
.contact-form-group {
  margin: 20px;
}
.contact-form-group label {
  margin-left: -5px !important;
}
.contact-submit {
  width: 20% ;
  border-radius: 18px !important;
  margin-top: 15px;
}