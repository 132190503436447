
.profile-general-info, .profile-company-info, .profile-social-info
{
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 1px 2px 5px 2px lightgray;
    padding: 20px;
    background: white;
}
.profile-general-header, .profile-company-header, .profile-social-header
{
    font-weight: 600;
    display: flex;
    text-transform: uppercase;
}

input[type=text]
{
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid lightgray;
}

#country, #region
{
    padding: 10px;
    border: none;
    border-bottom: 1px solid lightgray;
    width: 80%;
}

.upload-photo{
    display: none !important;
  }
  .custom-fileupload {
    display: inline-block;
    cursor: pointer;
  }
  .profile-choose-photo
  {
      background-color: peru;
      color: white;
      padding: 10px;
      border-radius: 6px;
  }
  .profile-save-group
  {
      text-align: center;
  }
  .form-label-icon
  {
      padding-right: 5px;
  }
  .profile-page
  {
      background-color: rgb(240, 232, 232);
  }
  #newpwdbutton, #otpbutton
  {
      display: none;
      margin-top: 20px;
  }
  .button-div
  {
      text-align: center;
      height: 200px;
      margin-top: 150px;
  }
  .profile-certificate-info
  {
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 1px 2px 5px 2px lightgray;
    padding: 20px;
    background: white;
    margin-top: 20px;
  }
  .profile-certificate-header
  {
    font-weight: 600;
    text-transform: uppercase;
  }
  .profile-certificate-button
  {
      margin-top: 35px;
      text-align: center;
  }
  #cameradiv, #takephoto
  {
    display: none;
  }
  #camera
  {
      margin-right: 10px;
  }
  .buttons-div
  {
    text-align: center !important;
  }
  .camera-icon
  {
      cursor: pointer;
      margin-top: 55px !important;
      text-align: center;
      border-radius: 6px;
  }
  #profilephoto:hover
  {
      background-color: lightgray;
      content: "\270E" !important;
      opacity: 0.2;
  }
  .text
  {
    margin-top: -100px;
    color: white;
    }
    
    .profilephoto:hover .text {
    visibility:visible;
    text-align: center;
    color: wheat;
    }
.camera-icon-div
{
    text-align: center !important;
    width: 20%;
    margin-left: 190px;
}
.pen-icon
{
    font-size: 30px;
}
#download_certificate_message
{
    color: peru;
}
#certificate_status
{
    text-align: center;
}
.profile-avatar
{
    border-radius: 100px;
    height: 150px !important;
    width: 150px !important;
}
.profile-form-labels
{
    font-weight: 600;
}
.profile-changepwd-div
{
    margin-bottom: 25px;
}
.profile-otplabel
{
    width: 150% !important;
}
.profile-otp
{
    margin-bottom: 30px;
}
.profile-badge-level-gold, .profile-badge-level-platinum, .profile-badge-level-silver, .profile-badge-level-bronze
{
    border-radius: 4px;
    padding: 5px;
}
.profile-badge-level-div
{
    margin-top: -9px;
}
.profile-badge-level-icon
{
    margin-top: -5px;
}