
.block-heading
{
  text-align: center;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  margin-top: 5px;
  position: relative;
  padding-bottom: 15px;
  margin-top: 40px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ff8a00;
  background: linear-gradient(to right,#df8013 40%,#e52e71 60%);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.block-heading
{
  position: relative;
  -webkit-animation-name: fadeInDown; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  animation-name: fadeInDown;
  animation-duration: 1s;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
}
100% {
    opacity: 1;
    transform: none;
}
}

img
{
  position: relative;
  
}

.heading-design
{
  text-align: center;
  margin-top: -20px;
  background: linear-gradient(to right,#2E5EED 50%,#e52e71 50%);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
}
#welmsg
{       
  padding-top: 20px;
  background-color: white;
}   
#dowapp
{
  background-color: brown;
  color: white;
  height: 60px;
  width: 275px;
  text-align: center;
  padding-top: 15px;
  border-radius: 5px;
}
#weltxt
{
  font-family:Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #77838f;
  line-height: 1.7;
  margin-top: 0;
  margin-bottom: 1rem;
  visibility: visible;
  animation-delay: 0.2s;
  animation-name: fadeInLeft;
  text-align: justify;
}
.homereg
{
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  
}
.sponsor-img
{
  width: 200px;
  height: 150px;
  padding-bottom: 10px;
}
.reg
{
  background-color: orange;
  text-align: center;
  padding-top: 60px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}
.registartion
{
  text-align: center;
  margin-top: -100px;
  color: black;
}
.fa-file-alt:hover
{
  color: black;
}
.sponsor-images
{
  height: auto;
  width: 150px;
  margin: 10px;
  border-radius: 20px;  
}
.home-marquee
{
  background: linear-gradient(90deg,#e52e2e,#ff7b00);
  color: white;
  padding: 10px;
}
h3
{
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    color: #c9fd01;
    font-size: 24px;
    text-align: center;
    padding-top: 20px;
    font-weight: 500;
    line-height: 1.1;
    
}
.smalltext{
	padding-top: 5px;
	font-size: 16px;
}
.Countdown{
  padding-bottom: 20px;
  color: white;
  text-align: center;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 36px;
} 
.row
{
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-top: 0%;
  margin-bottom: 0%;
}
.committee-images
{
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border: 3px solid lightgray;
  margin: 40px;
  margin-left: 70px !important;
}

.committee-block
{
 margin-top: 40px;
}
.committee-txt
{
  margin-left: 100px;
}
.committee-button
{
  background: #24388B;
  color: white;
  padding: 10px;
  border-radius: 6px;
  text-decoration: none;
}

.committee-button:hover
{
  background-color: #A508B2;
  color: white;
}
.committee-btn-block
{
  margin-top: 50px;
  margin-left: 24.5%;
}
.banner{
  width: auto;
  max-width: 100%;
}
.home-banner-div
{
  background-repeat: no-repeat;
  text-align: center !important;
}
.sponsors-home
{
  display: inline-block;
  margin: 10px;
  text-align: center !important;
}
.sponsors-section
{
  text-align: center !important;
}
.img-map
{
  overflow: hidden !important;
  margin-left: -10px;
  margin-top: -10px;
  width: 100.65% !important;
  margin-right: 10px !important;
  margin-bottom: -20px !important;
}
.home-slider-video-div
{
  background-color: rgba(250, 250, 250, 0.973) !important;
  height: 800px !important;
  margin-top: 0px;
}
.home-slider-video
{
  height: 635px !important;
}
.home-reg-button
{
  width: 25%;
  font-size: 20px !important;
  font-weight: bold !important; 
}

.home-banner-div {
  position: relative;
  
}
.banner-timer {
  position: absolute;
}
.rows-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdownbanner-element {
  padding: 12px;
  border-radius: 8px;
}
.countdown-home-wrapper span {
  margin: 2px;
}

@media screen and (max-width: 769px) {
  .home-reg-button {
    width: 80%;
    text-align: center;
  }
  .home-reg-button-link a{
    width: 91% !important;
    margin-left: 0px !important;
  }
  .banner-timer {
    left: 0px;
    margin-top: 26px;
  }
  .home-banner-div {
    height: 22vh;
  }
}