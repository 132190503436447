
*
{
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0;
    margin: 0;
}
.menubar
{
  width: 100% !important;
  height: 60px !important;
  box-shadow: 1px 1px 7px 1px gray;
  display: flex;
  justify-content: space-between !important;
}
.navbar-nav{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

}

.menu-item
{
  cursor: pointer;
  padding: 5px 15px !important;
  font-size: 15px;
}
#menulogo
{
  width:auto;
  height:35px;
  text-align: center;
}
.login-menu,.register-menu{
  padding: 5px 15px !important;
  font-size: 17px !important;
  text-align: center !important;
  font-weight: bold !important;
}
.menubar-items{
  margin-left: auto !important;
}
.nav-dropdown{
  margin-top: -10px !important;
  height: 20px !important;
}
.notification img{
  width: 20px !important;
}
.menubar-chat-notifications-icon{
  width: 35px !important;
}
.menubar-icons{
  width: 30px !important;
  margin-top: -5px !important;
}
@media only screen and (max-width: 768px) {
  .but1 {
   padding-left: 20px;
   padding-right: 20px;
   margin-left: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .but2 {
   padding-left: 80px;
   padding-right: 20px;
   margin-left: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .login-menu
  {
    margin-top: 10px;
    text-align: center;
    width: 20% !important;
    margin-left: -15px;
  }
  .register-menu
  {
    margin-top: 10px;
    text-align: center;
    width: 19% !important;
  }
  
}


@media screen and (max-width: 769px) {
  
  .register-menu
  {
    margin-top: 10px;
    text-align: center;
    width: 35% !important;
  }
  .login-menu{
    margin-top: 10px;
    text-align: center;
    width: 38% !important;
  }
  .menubar-items{
    margin-left: -20px !important;
    margin-right: -20px !important;
    padding-bottom: 15px !important;
    
  }
  .menu-item{
    min-width: 170px !important;
    text-align: center !important;
    margin-bottom: 15px !important;
  }
  .login{
    margin-left: 0px !important;
  }
  

}
.menu-profile-name
{
  text-align: center !important;
}

.navitem
{
  font-size: 20px;
  /*font-family: fantasy;*/
  font-family:  Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  
}
.dropdown-item
{
  background-color: none !important;
}
.dropdown-navitem
{
  color: white;
}
.dropdown-navitem:hover
{
  color: black;
}
.reg-btn
{
  float: right;
  margin-left: 50px;
  padding: 10px 20px;
  color: white;
}
.reg-btn:hover
{
  background-color: #189bc7;
  color: white;
  text-underline-position: none;
}
/* Footer CSS */
.footer
{
  background-color:#189bc7;

}

.copyright
{
  margin-left: 20px;
  margin-bottom: 30px;
  color: white;
}
.fab
{
  color: lightgray;
  padding-left: 10px;
  font-size: 35px;
  margin-right: 20px;
}
.fab:hover
{
  color: rgb(0, 0, 0);
}
.contact-footer
{
  margin-top: 20px;
}
.office-footer-1
{
  color: white;
  margin-bottom: 20px;
}
.office-hr
{
  color: white;
}
.hr-footer
{
  background-color: lightgray;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  border-radius: 4px;
}

.office-hr-footer
{
  background-color: lightgray;
  width: 80%;
  height: 1px;
  border-radius: 4px;
}
.office-footer-2
{
  color: white;
  margin-top: 20px;
  margin-bottom: 30px;
}
.footer-image
{
  margin: 20px;
}
.footer-images
{
  margin-left: 30px;
}
.nav-dropdown
{
  text-align: center;
  display: block !important;
}
.animation-footer
{
  position: relative;
  animation-name: zoomIn;
  animation-duration: 1s;
  
}
/*@keyframes zoomIn
{
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
}
50% {
    opacity: 1;
}
}*/
.collapse{
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.header-logo
{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px; 
  background: linear-gradient(90deg,#ff8a00,#e52e71);
}

.header-menu
{
  text-align: center !important;
  padding: 20px !important;
  background: linear-gradient(90deg,#ff8a00,#e52e71);
}
.header-hr
{
  background-color: white;
  width: 100%;
  margin-top: 22px;
  margin-left: 20px;
}
.header-row
{
  margin-left: 30px;
}
.header
{
  background: linear-gradient(90deg,#ff8a00,#e52e71);
}

.react-scroll-tot-top
{
  color: red !important;
  background: #129CF3 !important;
}

.profile-image
{
  border-radius: 100px;
  width: 80%;
}
.edit-profile
{
  margin-top: -10px;
  text-align: center;
}
.speakers
{
  float:  left !important;
}
#dropdown-menu
{
  display: inline-flex !important;
  margin-top: 11px;
}
.dropdown-title
{
  margin-top: -9px;
  margin-right: 5px;
}
.dropdown-item
{
  background: none !important;
}
.dropdown-item:hover
{
  color: rgb(75, 73, 73) !important;
  border: none !important;
  background-color: lightgray !important;
}
.menu-avatar
{
  border-radius: 50px;
  margin-left: 25px;
}

.profile-icon
{
  margin-top: -5px;
}
.notifications-menu
{
  margin: 0 -10px 0 15px;
}
.menu-avatar-div
{
  text-align: center !important;
}
.menu-avatar
{
  margin-left: 0;
  margin-bottom: -15px;
}
.footer-contact-form
{
  display: flex;
}
.footer-contact-textarea
{

}
.footer-contact-button
{
  margin: 30px 0 0 30px;
}
