.add-meetup-section {
    margin-left: 40px;
}
.no-meetups-text, .no-attendees-signedup
{
    text-align: center !important;
    color: gray;
    font-size: 18px;
    font-style: italic;
    padding: 70px;
}
.add-meetup-button {
    float: right;
    cursor: pointer;
    padding: 20px;
    background-color: rgb(93, 174, 70);
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    margin: 20px 0px 0 0;
    color: white;
    float: left;
}

.add-meetup-icon {
    margin-bottom: 20px;
    /* float: right; */
    background-color: lightgray;
    padding: 5px;
    border-radius: 20px;
}

.add-meetup-icon:hover {
    border-radius: 20px;
    box-shadow: 0px 0px 1px 3px gray;
}

.add-meetup-popup-modal {
    overflow: auto !important;
    height: 570px;
    max-width: 60%;
    max-height: 570px; 
    margin-top: 120px;
}

.add-meetup-popup-overlay {
    /* background: rgba(36, 123, 160, 0.7); */
}

.meetup-form-header {
    background-color: rgb(220, 177, 243) !important;
    font-weight: bold;
    font-size: 20px;
}

.meetup-form-connectionurl-input {
    width: 100% !important;
    margin-left: 0px !important;
}

.meetup-form-title, .meetup-form-desc, .meetup-form-maxsignups {
    margin-left: 13px;
}

.meetup-form-maxsignups-input, .meetup-form-title-input, .meetup-form-desc-input {
    width: 95.5% !important;
    margin-left: 13px !important;
}

.meetup-form-message {
    text-align: center;
    margin-top: 25px !important;
}

.meetup-form-btn-div {
    text-align: center;
    margin-top: 25px;
}

.meetup-form-submit-button {
    background-color: peru !important;
}

.meetup-form-label {
    font-weight: bold;
}

.meetup-card-div {
    /* background-color: rgb(186, 165, 196); */
    height: 390px;
    width: 330px;
    margin: 25px;
    border-radius: 6px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 2px 0px, rgba(14, 30, 37, 0.32) 0px 2px 10px 0px;
    max-width: 100%;
}

.meetup-card-div:hover {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgb(204, 219, 232) -3px -3px 6px 1px inset;
}

.meetup-card-img-div {
    height: 250px !important;
    width: 300px !important;
}

.meetup-card-img {
    padding: 20px 10px 10px 10px;
    /* height: 250px !important;
    width: auto;
    /* width: 300px !important; 
    max-width: 100% !important; */
    max-height: 240px !important;
    border-radius: 20px;
    width: auto;
    max-width: 100%;
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
}

.meetup-card-avatar {
    border-radius: 20px;
    width: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
}

.meetup-card-name {
    font-weight: bold;
    font-size: 20px;
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
}
.meetup-card-name:hover {
    text-decoration-line: underline;
    color: #129cf3;
}


.meetup-card-date {
    font-weight: bold;
}

#meetup-form-photo-div {
    text-align: center;
    margin-bottom: -30px;
}

.meetup-form-avatar {
    border-radius: 50%;
}

#meetup-form-photo {
    height: 150px;
    width: auto;
    max-width: 100% !important;
}

.meetup-form-upload-file {
    display: none !important;
}

.meetup-form-custom-fileupload {
    cursor: pointer;
}

.meetup-form-pen-icon {
    display: none;
    position: absolute;
    top: 15%;
    font-size: 20px;
    right: 50%;
}

#meetup_form_photo_upload_message {
    text-align: center;
}

.meetups-list-section {
}
.meetups-list-maindiv
{
    margin-left: 10px;
}
.meetup-card-edit-btn, .meetup-card-delete-btn, .meetup-card-download-btn {
    background: none !important;
    border: none !important;
    margin-top: -10px;
}

.meetup-card-icons {
    color: white;
    margin-left: -10px;
    border-radius: 50%;
    background-color: rgb(145, 144, 144);
    padding: 7px;
}

.meetup-card-icons:hover {
    border-radius: 50%;
    box-shadow: 0 0 1px 3px rgb(106, 106, 106);
}

.meetup-card-signup-btn {
    background-color: gray !important;
}

.meetup-card-signup-btn, .meetup-card-join-btn, .meetup-card-cancel-signup-btn {
    cursor: pointer;
    background: none !important;
    border: none !important;
    margin-top: -10px;
}

.meetup-card-status-label-div {
    float: right;
}

.meetup-card-status-label-upcoming, .meetup-card-status-label-live, .meetup-card-status-label-finished {
    padding: 2px 10px 2px 10px;
    font-weight: bold;
    margin-right: -15px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.meetup-card-status-label-upcoming {
    background-color: rgb(133, 108, 218);
}

.meetup-card-status-label-live {
    background-color: rgb(120, 209, 120);
}

.meetup-card-status-label-finished {
    background-color: rgb(233, 134, 134);
}
.meetup-card-status-label-closed
{
    background-color: rgb(233, 134, 134);
    padding: 0px 5px 0px 5px;
    margin-right: -10px;
    font-weight: bold;
    float: right;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.add-meetup-tabs-div {
    overflow: auto;
}

.meetup-popup-tabs-img-div {
    text-align: center;
}

.meetup-popup-tabs-name, .meetup-popup-tabs-date, .meetup-popup-tabs-desc {
    color: black;
    padding: 10px;
}
.meetup-popup-tabs-desc
{
    text-align: left !important;
}

.meetup-popup-tabs-img, .meetup-popup-tabs-avatar {
    max-height: 250px !important;
    border-radius: 4px;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
}

.meetup-user-status-label-div {
    float: left !important;
}

.meetup-user-status-label {
    background-color: rgb(208, 164, 120) !important;
    padding: 2px 10px 2px 10px;
    font-weight: bold;
    margin-left: -15px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.meetup-host-status-label {
    float: left !important;
    background-color: rgb(102, 172, 202) !important;
    padding: 2px 10px 2px 10px;
    font-weight: bold;
    margin-left: -15px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.meetup-tabs-users-list {
    padding: 0px;
    color: gray;
}

.meetuptabs-video-chat {
    height: 450px;
    border: none;
}

.meetup-card-btns-div {}

.signup-count-text {
    font-size: 14px;
    color: gray;
    float: left;
}

.meetup-card-btn-div1 {
    text-align: right !important;
}

.meetup-card-btn-div {}

.meetup-card-user-btn-div {
}

.meetup-filter-div {
    margin: 20px 0px 0px 0px;
}

.meetup-filter-select {
    width: 100%;
    float: right;
}

.meetup-filter-select-attendee {
    width: 94%;
    float: left;
}

.meetup-topbar {
    margin-bottom: 20px;
}

.meetup-chat-offline-message {
    color: gray;
    text-align: center;
}

.meetup-attendee-name, .meetup-attendee-email, .meetup-attendee-phone {
    margin-top: 15px;
}
.meetup-video-chat-signup
{
    color: #129CF3 !important;
    cursor: pointer;
}
.meetup-video-chat-signup{
 text-decoration-line: underline;   
}