.speader3-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.speakers3-card-div{
    width: 300px;
    border: 1px solid rgb(207, 207, 207);
    margin: 15px;
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    
}
.speakers3-card-div:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    scale: 1.02;
    transition: 1s ease;
}
.speakers3-imgdiv{
    width: 170px;
    height: 170px;
}
.speakers3-img, .speakers3-avatar, .speaker3-popup-img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.speakers3-contact-icon{
    border-radius: 50px;
    padding: 10px;
    background-color: lightgray;
    margin-top: 30px;
    margin-left: 10px;
}
.speakers3-contact-icon:hover
{
    box-shadow: 0px 0px 1px 3px gray;
    scale: .8;
    transition: 1s ease-in;
}
.speakers3-contact-name
{
    font-size: 18px;
    font-weight: bold;
}
.speakers3-contact-title,.speakers3-contact-company{
    font-size: 13px;
    font-size: 14px;
    padding: 0 10px 0 10px;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    text-overflow: ellipsis;
    margin-top: -10px;
}
.speakers3-profile-btn{
    font-size: 14px !important;
}
.speakers3-profile-btn:hover{
    scale: 1.1;
    transition: 1s ease;
}

@media(max-width:650px){
    .speakers3-card-div{
        width: 100%;
    }
    .attendee-search-div input{
        width: 100% !important;
        margin-left: 0px !important;
    }
}