* {
  margin: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-control-prev, .carousel-control-next {
  cursor: pointer;
}

#exhlogo {
  padding-right: 20px;
}

#dates {
  background-color: rgb(56, 171, 216);
}

h5 {
  color: black;
}

.row {
  width: 100%;
}

#mimg {
  height: 200px;
}

.email {
  cursor: pointer;
}

.registration {
  text-decoration: underline;
  font-weight: bold;
}

.label {
  font-weight: bolder;
}

#gmap {
  width: 100%;
}

#googlebut {
  padding-top: 0px;
}

#dowapp {
  background-color: brown;
  color: white;
  height: 60px;
  width: 275px;
  text-align: center;
  padding-top: 15px;
}

#welimg {
  height: 180px;
  width: 250px;
  padding-top: 0px;
}

#schregbut {
  padding-left: 100px;
  padding-right: 100px;
}

#schwelmar {
  margin-left: 20px;
}

#schregimg {
  height: 150px;
  width: 450px;
  padding-left: 200px;
  padding-top: 0px;
}

#regroute {
  padding-top: 6px;
  padding-left: 12px;
}

#welrec {
  background-color: rgb(55, 55, 245);
  color: white;
  font-family: 'Times New Roman', Times, serif;
}

#datetime {
  background-color: whitesmoke;
}

#reg {
  background-color: lightsalmon;
  height: 200px;
  text-align: center;
  padding-top: 60px;
}

#emailicon {
  padding-top: 00px;
}

#download {
  height: 200px;
  text-align: center;
  padding-top: 40px;
  background-color: aquamarine;
}

#condet {
  padding-top: 30px;
}

#gp {
  padding-right: 10px;
}

#contact {
  background-color: white;
  height: 250px;
  padding-top: 30px;
}

#aboutheader {
  text-align: center;
  font-family: sans-serif;
  height: 80px;
  padding-top: 10px;
  font-weight: inherit;
  background-color: rgb(63, 63, 216);
  color: white;
}

#appbut {
  padding-top: 10px;
}

#navitem:hover {
  background-color: black;
}

#map {
  width: 400px;
  height: 300px;
}

.regsection {
  display: block;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
  display: visible;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #189bc7;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: #F5F5F5;
}

.watchlive {
  animation: blinkingText 1s infinite;
  font-family: futura;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: peru;
  font-size: 18px;
  margin-left: 15px;
}

.fa-play {
  margin-right: 10px;
}

@keyframes blinkingText {
  from {
    color: #129CF3;
  }
  to {
    color: green;
  }
}

.myClassname {
  width: 100%;
  height: 380px;
  border: none;
}

.react-player {
  width: 100%;
  height: 450px;
}

.loading-div {
  height: 500px;
  display: table;
}

.loading-text {
  vertical-align: middle;
  display: table-cell;
  font-size: 25px;
  animation: blinkingText 1s infinite;
  font-family: futura;
  width: 100%;
  margin: 0 auto;
  color: peru;
}

.refresh-site {
  font-size: 40px;
  margin-top: 20px;
}

.goog-te-combo {
  padding: 5px;
  width: 15%;
  background-color: #e06e4f;
  color: white;
  letter-spacing: 0.7px;
  font-size: 14px;
}

#google_translate_element {
  padding: 10px;
}